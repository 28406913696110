import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Footer from "../components/footer"
import { Box, Button, Grid, Themed } from "theme-ui"
import getSlug from "speakingurl"

const IndexPage = ({ data }) => {

  const dataFragments = data.indexJason
  const dataFragmentsHeader = data.headerJson

  return (
    <Themed.root>
      <Grid
        as={`div`}
        gap={0}
        sx={{
          gridTemplateColumns: `1fr`,
          gridTemplateRows: [ `100vh auto auto`,
                              `100vh auto auto`,
                              `100vh auto auto`,
                              `100vh 1fr auto`,
                              `100vh 1fr auto`,
                              `100vh 1fr auto`
                            ],
          gridTemplateAreas:[`"hero" "main" "footer"`],
          header:{
            position: `absolute`,
            top: 3,
            right: 3,
          },
          ".hero": {
            backgroundColor: `gray.2`,
            gridArea: `hero`,
            px: 3,
            paddingBottom: 3,
            display: `grid`,
            placeItems: `flex-end flex-start`,
          },
          ".hero-content p": {
            color: `black`,
            maxWidth: [`auto`, `auto`, `auto`, `75%`, `50%`, `50%`],
          },
          ".link-to-content": {
            color: `black`,
            borderBottom: `none`,
            display: `inline-block`,
            ":hover": {
              color: `black`,
              borderBottom: `none`,
            }
          },
          main: {
            gridArea: `main`,
            px: 3,
          },
          footer: {
            gridArea: `footer`,
          },
          h1: {
            color: `black`,
            fontSize: [7, 8, 8, 9, 10, 11],
            fontWeight: `normal`,
          },
          ".link-to-lang": {
            color: `black`,
            borderBottom: `none`,
            fontSize: 0,
            textTransform: `uppercase`,
            ":hover": {
              color: `black`,
              borderBottom: theme => `1px solid ${theme.colors.gray[1]}`,
            }
          },
          ".content": {
            my: 5,
          },
          ".items": {
            gridTemplateColumns: [  `1fr 1fr`,
                                    `1fr 1fr 1fr`,
                                    `1fr 1fr 1fr`,
                                    `1fr 1fr 1fr`,
                                    `1fr 1fr 1fr`,
                                    `1fr 1fr 1fr`
            ]
          },
          ".items-stack": {
            gridTemplateColumns: [  `1fr`,
                                    `1fr 1fr 1fr`,
                                    `1fr 1fr 1fr`,
                                    `1fr 1fr 1fr`,
                                    `1fr 1fr 1fr`,
                                    `1fr 1fr 1fr`
            ]
          },
          ".items h2, .items h4, .items-stack h4": {
            fontSize: 1,
            my: 2,
          },
          ".subtitle": {
            fontSize: 2,
            fontWeight: `body`,
            margin: 0,
            "&:after": {
              backgroundColor: `black`,
              content: `""`,
              display: `block`,
              height: `1px`,
              width: 6,
              my: 2,
            },
          }
        }}
      >
        <Box as="header">
          <Box as="div" className="nav">
            <Themed.a href={dataFragmentsHeader.fragment_3} className="nav-link link-to-lang">{dataFragmentsHeader.fragment_2}</Themed.a> | <Themed.a href={dataFragmentsHeader.fragment_5} className="nav-link link-to-lang">{dataFragmentsHeader.fragment_4}</Themed.a> | <Themed.a href={dataFragmentsHeader.fragment_7} className="nav-link link-to-lang">{dataFragmentsHeader.fragment_6}</Themed.a>
          </Box>
        </Box>
        <Box className="hero">
          <Box className="hero-content">
            <Themed.h1 dangerouslySetInnerHTML={{ __html:dataFragments.fragment_13}}/>
            <Themed.p dangerouslySetInnerHTML={{ __html:dataFragments.fragment_1}} />
            <Themed.p>{dataFragments.fragment_2}</Themed.p>
            <Themed.p dangerouslySetInnerHTML={{ __html:dataFragments.fragment_20}} />
            <Themed.a as={Link} to="#main" arial-label="link-to-main-content" className="link-to-content">&#x02193;</Themed.a>
          </Box>
        </Box>
        <Box
          as={`main`}
          id="main"
          className="main-content"
        >
          <Grid
            className="content grants"
            columns={[`1fr`, `1fr`, `1fr`, `25vw 1fr`, `25vw 1fr`, `25vw 1fr 6.25vw`]}
          >
            <Themed.h2 className="subtitle">{dataFragments.fragment_3}</Themed.h2>
            <Box>
              <Themed.p>{dataFragments.fragment_4}</Themed.p>
              <Grid className="items">
              {data.allGrants.nodes.map((node, i) => (
                <Box key={i}>
                  <Themed.a as={Link} to={`/${node.slug}`}>
                    <GatsbyImage
                      image={node.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                      alt={node.featuredImage.node.altText}
                      style={{
                        height: `12rem`,
                        overflow: `hidden`
                      }}
                      imgStyle={{
                        height: `100%`,
                        objectFit: `cover`,
                      }}
                    />
                    <Themed.h2
                      dangerouslySetInnerHTML={{ __html:node.title}}
                    />
                  </Themed.a>
                </Box>
              ))}
              </Grid>
            </Box>
          </Grid>
          <Grid
            className="content videos"
            columns={[`1fr`, `1fr`, `1fr`, `25vw 1fr`, `25vw 1fr`, `25vw 1fr 6.25vw`]}
          >
            <Themed.h3 className="subtitle">{dataFragments.fragment_5}</Themed.h3>
            <Grid className="items">
              {data.otherVideos.nodes.map((node, i) => (
                <Box key={i}>
                  <Themed.a as={Link} to={`/${node.slug}`} className="link-to-video">
                    <GatsbyImage
                      image={node.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                      alt={node.featuredImage.node.altText}
                      style={{
                        height: `12rem`,
                        overflow: `hidden`
                      }}
                      imgStyle={{
                        height: `100%`,
                        objectFit: `cover`,
                      }}
                    />
                    <Themed.h4
                      dangerouslySetInnerHTML={{ __html:node.title}}
                    />
                  </Themed.a>
                </Box>
              ))}
            </Grid>
          </Grid>
          <Grid
            className="content other-access"
            columns={[`1fr`, `1fr`, `1fr`, `25vw 1fr`, `25vw 1fr`, `25vw 1fr 6.25vw`]}
          >
            <Themed.h3 className="subtitle">{dataFragments.fragment_14}</Themed.h3>
            <Grid className="items-stack">
              <Button><Themed.a href={`/${getSlug(dataFragments.fragment_6)}-ca.pdf`}>{dataFragments.fragment_6} (PDF)</Themed.a></Button>
              <Button><Themed.a href={`/${getSlug(dataFragments.fragment_7)}.pdf`}>{dataFragments.fragment_7} (PDF)</Themed.a></Button>
            </Grid>
          </Grid>
          <Grid
            className="content other-access"
            columns={[`1fr`, `1fr`, `1fr`, `25vw 1fr`, `25vw 1fr`, `25vw 1fr 6.25vw`]}
          >
            <Themed.h3 className="subtitle"></Themed.h3>
            <Grid className="items-stack">
              <Button><Themed.a href={dataFragments.fragment_8}>{dataFragments.fragment_9}</Themed.a></Button>
              <Button><Themed.a href={dataFragments.fragment_10}>{dataFragments.fragment_11}</Themed.a></Button>
              <Button><Themed.a href={getSlug(dataFragments.fragment_12)}>{dataFragments.fragment_12}</Themed.a></Button>
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </Grid>
    </Themed.root>
  )

}

export default IndexPage

export const pageQuery = graphql`
  query IndexPage {
    allGrants: allWpPost(
      sort: {order: DESC, fields: date}
      filter: {categories: {nodes: {elemMatch: {databaseId: {eq: 107}}}}}
    ) {
      nodes {
        title
        excerpt
        slug
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    otherVideos: allWpPost(filter: {categories: {nodes: {elemMatch: {databaseId: {eq: 498}}}}}) {
      nodes {
        title
        slug
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    indexJason: dataJson(slug: {eq: "page-index"}) {
      fragment_1
      fragment_2
      fragment_3
      fragment_4
      fragment_5
      fragment_6
      fragment_7
      fragment_8
      fragment_9
      fragment_10
      fragment_11
      fragment_12
      fragment_13
      fragment_14
      fragment_20
    }
    headerJson: dataJson(slug: {eq: "header"}) {
      fragment_2
      fragment_3
      fragment_4
      fragment_5
      fragment_6
      fragment_7
    }
  }
`